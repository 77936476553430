<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>

<script>
import { resize } from "../public/rem";
import {all_list} from "@/api/request/all";
export default {
  created() {
    //当屏幕尺寸发生变化，调用函数重新计算rem
    if (window.innerWidth < 768){
      window.onresize = function () {
        resize();
      };
      // 设置初始触发一次
      resize();
    }
  },

  methods:{

  }

};
</script>

<style lang="scss">
html,body{
  margin: 0;
  padding: 0;
  height: 100%;

}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
}
p{
  margin: 0;
}

</style>
