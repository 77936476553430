import Vue from 'vue'
import Vuex from 'vuex'
import {all_list} from '@/api/request/all'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    user:'',
    seo:''
  },
  mutations: {
    // 保存用户user
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_SEO: (state, seo) => {
      state.seo = seo
    },
  },
  actions: {
    async fetchRouteTitles({ commit }) {

      all_list().then((res)=>{
        if (res.status == 1){
          commit('SET_USER',res.data)
          commit('SET_SEO',res.seo)
        }
      })

    },
  },
  modules: {
  },
  // 当state中的值发生改变，此时localStorage中的vuex的值会同步把state中的所有值存储起来，当页面刷新的时候，state的值会从localStorage自动获取vuex的value值，赋值到state中
  plugins: [createPersistedState({
    paths: [ 'user','seo'],
    storage: { // 存储方式定义
      getItem: (key) => localStorage.getItem(key), // 获取
      setItem: (key, value) => localStorage.setItem(key, value), // 存储
      removeItem: (key) => localStorage.removeItem(key) // 删除
    }
  })]
})
