import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//安装的组件
//element-ui ui组件库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
//animate 动效库
import animated from 'animate.css'
Vue.use(animated)
//seo 优化 npm install vue-meta-info
import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);

import filters from "@/common/filters";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),

  created() {
   store.dispatch('fetchRouteTitles');
  },
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
