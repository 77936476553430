import request from "../utils/request";

//大部分页面数据
export function all_list(data) {
    return request({
        method: 'post',
        url: '/Api/config',
        data: data //可以简写为data
    })
}
//冥想语录
export function all_mxyl(data) {
    return request({
        method: 'post',
        url: '/Api/yulu',
        data: data //可以简写为data
    })
}
//隐私政策接口
export function privacy(data) {
    return request({
        method: 'post',
        url: '/Api/privacy',
        data: data //可以简写为data
    })
}

//冥想资讯 分类
export function mes_classify(data) {
    return request({
        method: 'post',
        url: '/Api/newstype',
        data: data //可以简写为data
    })
}
//分类列表
export function mes_classify_list(data) {
    return request({
        method: 'post',
        url: '/Api/newslist',
        data: data //可以简写为data
    })
}
//分类列表 详情
export function mes_classify_list_xq(data) {
    return request({
        method: 'post',
        url: '/Api/article',
        data: data //可以简写为data
    })
}
//官方活动
export function gfhd_list(data) {
    return request({
        method: 'post',
        url: '/Api/gfhdlist',
        data: data //可以简写为data
    })
}
//官方活动 详情
export function gfhd_list_xq(data){
    return request({
        method: 'post',
        url: '/Api/gfhdarticle',
        data: data //可以简写为data
    })
}
//关于我们
export function gywm(data){
    return request({
        method: 'post',
        url: '/Api/aboutus',
        data: data //可以简写为data
    })
}
//加入我们
export function jrwm(data){
    return request({
        method: 'post',
        url: '/Api/joinus',
        data: data //可以简写为data
    })
}
//资讯列表接口
export function mes_wzlist(data){
    return request({
        method: 'post',
        url: '/Api/newslist',
        data: data //可以简写为data
    })
}

//友情链接

export function footer_yqlj(data){
    return request({
        method: 'post',
        url: '/Api/yqlink',
        data: data //可以简写为data
    })
}