// 检测时间格式为YYYY-MM-DD
const format = num => num < 10 ? '0' + num : num;

const filters = {
    /*
    * TODO: 时间格式化到天
    * */
    formatDateAt(time){
        const date = new Date(time*1000),
            y = date.getFullYear(),
            m = date.getMonth() + 1,
            d = date.getDate();
        return [y, format(m), format(d)].join('-');
    },
    formatDates(time){
        const date = new Date(time),
            y = date.getFullYear(),
            m = date.getMonth() + 1,
            d = date.getDate();
        return [y, format(m), format(d)].join('-');
    },
    formatDate(time){
        const date = new Date(time*1000),
            y = date.getFullYear(),
            m = date.getMonth() + 1,
            d = date.getDate();
        return [y, format(m), format(d)].join('.');
    },

    formatDateAts(time){
        const date = new Date(time*1000),
          m = date.getMonth() + 1,
          d = date.getDate();
        return format(m) +'月'+ d+'日'
    },
    formatDateAtn(time){
        if(time){
            time = isNaN(Number(time)) ? Date.now() : Number(time);
            const date = new Date(time*1000),
                y = date.getFullYear(),
                m = date.getMonth() + 1,
                d = date.getDate();
            return [y, format(m), format(d)].join('-');
        }else {
            return ''
        }

    },
    /*
    * TODO: 时间格式化到秒
    * */
    formatDateTimeAt(time){
    	time = isNaN(Number(time)) ? Date.now() : Number(time);
        const date = new Date(time*1000),
            y = date.getFullYear(),
            M = date.getMonth() + 1,
            d = date.getDate(),
            h = date.getHours(),
            m = date.getMinutes(),
            s = date.getSeconds();
        return [[y, format(M), format(d)].join('-'), [format(h), format(m), format(s)].join(':')].join(' ');
    },
    formatDatefm(time){
        time = isNaN(Number(time)) ? Date.now() : Number(time);
        const date = new Date(time*1000),
            y = date.getFullYear(),
            M = date.getMonth() + 1,
            d = date.getDate(),
            h = date.getHours(),
            m = date.getMinutes(),
            s = date.getSeconds();
        return [ [format(h), format(m)].join(':')].join(' ');
    },
    jqjq(value){
       if (value >1000){
           var money  =  Number(value).substr(str.length-3);
           return money
       }else{
           return  value
       }

    },
    numc(num){
        if (num > 99){
            return '99';
        }else {
            return num;
        }
    },
    jydjs(time){
        var sj=time*1000
        var date1 = new Date().getTime();
        var date2 = new Date(sj).getTime();
        var mss = date2 - date1;
        // 将时间差（毫秒）格式为：天时分秒
        var days = parseInt(mss / (1000 * 60 * 60 * 24));
        var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = parseInt((mss % (1000 * 60)) / 1000);

        if (days >= 0 && days <= 9) {
            // days = "0" + days;
            days =  days;
        }
        if (hours >= 0 && hours <= 9) {
            hours = "0" + hours;
        }
        if (minutes >= 0 && minutes <= 9) {
            minutes = "0" + minutes;
        }
        if (seconds >= 0 && seconds <= 9) {
            seconds = "0" + seconds;
        }
        var a =hours+'时'+minutes+'分'+seconds+'秒'

        return  a
    },
    mpzsname(name){
         if (name == 'null' || name == '' ||name == undefined || name == null){
             return uni.getStorageSync('name')
         }else {
             return  name
         }
    },
    name_regular(name){
    var reg = /(?<=.)./g;
     return  name.replace(reg, '*');
    },
    phone_regular(phone){
        return  phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
    //运费添加元
    yunfei(name){
        if (name== 0){
            return '包邮'
        }else {
            return name+'元';
        }

    },
    //地址判断
    dzpd(name){
        if (name == undefined){
            return '暂无';
        }else {
            return  name;
        }
    },
    //一周转换
    zhouzf(vlaue){
        if (vlaue == 1){
            return '周一'
        }else if (vlaue == 2){
            return '周二'
        }else if (vlaue == 3){
            return '周三'
        }else if (vlaue == 4){
            return '周四'
        }else if (vlaue == 5){
            return '周五'
        }else if (vlaue == 6){
            return '周六'
        }else if (vlaue == 7){
            return '周日'
        }

    }
}

export default filters;
