
import axios from 'axios';
import { getToken } from './getToken';
import { Message } from 'element-ui';

// 创建axios实例
//console.log(window.location.origin)
//var http ='https://www.fanyu888.com/';//测试域名
var http=window.location.origin; //线上域名
const request = axios.create({
    baseURL: http+'/admins/index.php', // 修改为HTTPS协议
    withCredentials: false, // 跨域请求时是否需要访问凭证
    timeout: 3 * 1000, // 请求超时时间
});

// 请求拦截器 - 根据需要动态设置headers
request.interceptors.request.use((config) => {
    //const token = getToken('token');
    // if (token) {
    //     config.headers['token'] = token; // 携带token
    //     config.headers['Content-type'] = 'application/json';
    // }
    config.headers['Content-type'] = 'application/x-www-form-urlencoded';
    return config;
}, (error) => {
    console.error('Request Error:', error); // 增加错误日志
    return Promise.reject(error);
});

// 响应拦截器 - 统一处理响应数据和错误
request.interceptors.response.use((response) => {
    let { status, msg } = response.data;
    if (status !== 200) {
        // 使用预定义的错误消息或本地化处理
        msg = msg || 'An error occurred.';
      //  Message({ message: msg, type: 'warning' });
    }
    return response.data;
}, (error) => {
    console.error('Response Error:', error); // 增加错误日志
    // 可以根据需要对错误进行进一步处理或分类
    return Promise.reject(error);
});

// 封装Message方法为全局提示函数
function showMessage(message, type = 'info') {
    Message({ message, type });
}

export default request; // 记得暴露出去