function resize() {
    //  获取html的宽度
    let htmlW = document.documentElement.clientWidth;
    // 获取body的宽度
    let bodyW = document.body.clientWidth;
    // 兼容处理
    let W = htmlW || bodyW;
    // 设置html的font-size大小
    // 因为设计图尺寸是750，所以平分，这样*10之后，1rem就等于10px;
    // 这样设置 750px尺寸下，1rem=10px   写代码的时候尺寸根据这个算
    //屏幕在放大或缩小时 1rem的值也会等比例放大或缩小
    document.documentElement.style.fontSize = (W / 750) * 40 + "px";
}
export { resize };