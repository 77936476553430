import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '/',
        component: () => {
            if (window.innerWidth > 768){
                return  import('../views/pc/home.vue')
            }else {
                return  import('../views/yd/home.vue')
            }
        },
        meat: {
            keepAlive: false,
            title:'11111111'
        }
    },
    {
        path: '/activity',
        name: '/activity',
        component: () => {
            if (window.innerWidth > 768){
                return  import('../views/pc/activity')
            }else {
                return  import('../views/yd/activity')
            }

        },
        meat: {
            keepAlive: true
        }
    },
    {
        path: '/message',
        name: '/message',
        component: () => {
            if (window.innerWidth > 768){
                return  import('../views/pc/message')
            }else {
                return  import('../views/yd/message')
            }

        },
        meat: {
            keepAlive: true
        }
    },
    {
        path: '/mesdetail/:type/:id',
        name: '/mesdetail',
        alias: '/:type/:id',
        component: () =>{
            if (window.innerWidth > 768){
                return   import('../views/pc/mesdetail')
            }else {
                return  import('../views/yd/mesdetail')
            }

        }
    },
    {
        path: '/privacy',
        name: '/privacy',
        component: () => {
            if (window.innerWidth > 768){
                return    import('../views/pc/privacy')
            }else {
                return   import('../views/yd/privacy')
            }


        },
        meat: {
            keepAlive: true
        }
    },
    {
        path: '/about',
        name: '/about',
        component: () =>{
            if (window.innerWidth > 768){
                return    import('../views/pc/about')
            }else {
                return   import('../views/yd/about')
            }
        } ,
        meat: {
            keepAlive: true
        }
    },
    {
        path: '/join',
        name: '/join',
        component: () => {
            if (window.innerWidth > 768){
                return    import('../views/pc/join')
            }else {
                return   import('../views/yd/join')
            }

        },
        meat: {
            keepAlive: true
        }
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL ,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 每次路由切换时，都回到页面顶部
        if (to.path == '/mesdetail' ) {
            return { x: 0, y: 0 }
        }

    }
})
router.beforeEach(async (to, from, next) => {
    //判断是否需要缓存
    // if (to.path == '/activity' || to.path == '/message' || to.path == '/privacy' || to.path == '/about' || to.path == '/join') {
    //     to.meta.keepAlive = true;  // 让 列表页 缓存，即不刷新
    // }
    next();

})
export default router
